.btn-line-b {
    @extend .button;
    border: 1px solid $heading-color;
    color: $white;
    min-width: 105px;
    background: $heading-color;
    border-radius: 30px;
    padding: 10px;
    margin-left: auto;
    margin-right: 30px;
    &:hover {
      background: $accent-color;
      color: $white;
      border-color: $accent-color;
    }
    &:disabled,
    &[disabled] {
      border: rgba(187, 187, 187, 0.7);
      background-color: rgba(187, 187, 187, 0.7);
      color: #666666 !important;
      box-shadow: none;
    }
  }

.popup-content-b {
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    width: 600px;
    height: 200px;
    padding: 20px;
    position: relative;
}

.popup-content-b h3 {
    justify-self: start;
    margin: 0 0 15px;
    font-size: 1.5em;
    color: #333;
}

.upload-popup-b {
    margin-bottom: 20px;
}

.upload-popup-b input[type="file"] {
    display: block;
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1em;
}

.popup-buttons-b {
    display: flex;
    justify-content: space-between;
}

.popup-buttons-b button {
    padding: 10px 15px;
    border: none;
    border-radius: 20px;
    font-size: 1em;
    cursor: pointer;
    transition: background-color 0.3s;
}

.cancel-btn-b {
    background-color: #080707; 
    margin-left: 280px;
    color: white;
    border-radius: 20px;
}

.submit-btn-b {
    background-color: #4CAF50; 
    color: white;
}

.cancel-btn-b:hover {
    background-color: #3431d1;
}

.submit-btn-b:hover {
    background-color: #3431d1;
}

.tooltip {
    position: relative;
    width: 100%;
}
.tooltip .tooltiptext {
    visibility: hidden;
    max-width: 300px;
    width: auto;
    background-color: #fff;
    color: #000;
    text-align: center;
    border-radius: 5px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 100%; 
right: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
}
.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}

.yoddha-id {
    width: 7%;
}

