.login-left-panel {
    width: 593px;
    // height: 100vh;
  
    .login-bg {
      height: 100%;
      position: relative;
      background: linear-gradient(180deg, #005665 0%, #0BBDDD 54.12%);
      // border-radius: 20px 0 0 20px;
      overflow: hidden;
      .login-bg-img {
        position: absolute;
        bottom: -6px;
        right: -60px;
        // text-align: center;
        margin: 0 auto;
        padding: 50px 0px 0px;
      }
      .logo-img {
        padding-left: 50px;
        padding-top: 50px;
        img {
          width: auto;
        }
      }
    }
  }
  .register{
    background: $white;
    width: 600px;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    padding: 100px 50px;
    .tab-pane {
      padding-top: 24px;
      .material {
        .form-input {
          width: 100%;
          padding: 13px 45px 13px 60px;
          font-size: 13px;
          font-weight: 500;
        }
        input {
          margin-bottom: 20px;
        }
        .otp-time {
          font-size: 11px;
          line-height: 16px;
          color: rgba($heading-color, 0.5);
          position: absolute;
          right: 15px;
          top: 18px;
        }
        .form-label {
          &:before {
            left: 60px;
            top: 16px;
          }
        }
        .form-input:focus ~ label:before {
          top: -8px;
        }
        .form-input:not(:placeholder-shown) ~ label:before {
          top: -8px;
          font-size: 12px;
          background: #ffffff;
      }
        .icn-login {
          position: absolute;
          left: 23px;
          top: 14px;
          color: $heading-color;
          font-size: 21px;
        }
      }
    }
  }
  .login-right-panel {
    background: $white;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    // padding-left: 100px;
    border-radius: 20px;
    padding: 100px;
    h2 {
      font-weight: 600;
      font-size: 22px;
      line-height: 30px;
      color: $heading-color;
    }
    .login-tab {
      .nav-pills {
        display: inline-flex;
        padding: 5px;
        margin-top: 15px;
        .nav-item {
          // margin-right: 40px;
          display: inline-flex;
  
          .nav-link {
            background: transparent;
            font-weight: normal;
            font-size: 16px;
            line-height: 20px;
            color: rgba($heading-color, 0.87);
            border-bottom: 2px solid transparent;
            border-radius: 0px;
            padding-left: 0px;
            padding-right: 0px;
            cursor: pointer;
  
            &.active {
              font-weight: 600;
              color: #0bbddd;
              border-color: #0bbddd;
            }
          }
        }
      }
      .tab-pane {
        padding-top: 24px;
        .material {
          .form-input {
            width: 360px;
            padding: 13px 45px 13px 60px;
            font-size: 13px;
            font-weight: 500;
          }
          input {
            margin-bottom: 20px;
          }
          .otp-time {
            font-size: 11px;
            line-height: 16px;
            color: rgba($heading-color, 0.5);
            position: absolute;
            right: 15px;
            top: 18px;
          }
          .form-label {
            &:before {
              left: 60px;
              top: 16px;
            }
          }
          .form-input:focus ~ label:before {
            top: -8px;
          }
          .form-input:not(:placeholder-shown) ~ label:before {
            top: -8px;
            font-size: 12px;
            background: #ffffff;
        }
          .icn-login {
            position: absolute;
            left: 23px;
            top: 14px;
            color: $heading-color;
            font-size: 21px;
          }
        }
      }
    }
    .password-show {
      font-size: 20px;
      position: absolute;
      right: 18px;
      top: 12px;
    }
    .form-input-captcha {
      width: 228px;
      padding: 15px 45px 15px 60px;
      font-size: 13px;
      font-weight: 500;
    }
  }
  .login-outer {
    background: $backgroundcolor;
    align-items: center;
    justify-content: center;
    // border-radius: 20px;
    display: flex;
    height: 100vh;
    // width: 1040px;
    margin: 0 auto;
    .error {
       margin-left: 0px !important;
      input {
        border: 1px solid #ff2e2e;
      }
      .erroe-txt {
        font-weight: normal;
        font-size: 10px;
        line-height: 15px;
        color: #ff2e2e;
        display: block;
        margin-top: -16px;
        margin-bottom: 20px;
      }
    }
  }
  
  .otp-success-icon {
    background: #34c48b;
    height: 16px;
    width: 16px;
    border-radius: 100%;
    color: $white;
    font-size: 12px;
    text-align: center;
    position: absolute;
    top: 18px;
    right: 16px;
  }
  
  .tab-content > .tab-pane {
    display: none;
  }
  .fade:not(.show) {
    opacity: 0;
  }
  
  .fade {
    transition: opacity 0.15s linear;
  }
  .tab-content > .active {
    display: block;
  }
  .login-heading {
    span {
      font-weight: normal;
      font-size: 26px;
      line-height: 30px;
      color: #1f1f1f;
      display: block;
    }
    h1 {
      font-weight: 600;
      font-size: 40px;
      line-height: 50px;
      color: #1f1f1f;
    }
  }
  
  .forget-password {
    font-weight: normal;
    font-size: 11px;
    line-height: 16px;
    text-align: right;
    display: block;
    color: $heading-color;
    position: relative;
    top: -16px;
  }
  
  .login-txt {
    padding-left: 50px;
    padding-top: 40px;
    padding-right: 50px;
    span {
      font-weight: 400;
  font-size: 14px;
  line-height: 22px;
      color: $white;
    }
    h1 {
      font-style: normal;
      font-weight: 600;
  font-size: 36px;
  line-height: 60px;
      color: $white;
    }
  }
  
  .login-main{
    background-color: #F4FCFF;
    width: 100%;
    .logo-img{
      width: 100%;
      padding: 50px 0;
    }
  }

  .reload {
    font-size: 28px;
    position: relative;
    top: -10px;
    cursor: pointer;
    color: #000;
  }

  .hidden {
    display: none;
  }

  .logingmail {
    border: 1px solid #d3d3d3;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
    color: #000;
  }
  .logingmail, .playStoreCommon {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .custom-login-button{
    min-width: 350px !important;
  }
  @media(max-width: 600px){
    .newregistration{
      width: 100% !important;
    }
      }