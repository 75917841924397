.map-dealer-outer {
  background: $white;
  border-radius: 8px;
  padding: 30px;
  margin-top: 20px;

  .map-filter {
    padding-top: 20px;
    display: flex;

    fieldset {
      width: calc(20% - 10px);
      margin-right: 10px;
    }
  }
}

.dealer-details-outer {
  background: #EFF2F9;
  border-radius: 25px;
  padding: 30px;
  margin-top: 30px;

  h3 {
    font-weight: 600;
    font-size: 16px;
    line-height: 30px;
    color: $heading-color;
  }

  ul {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;

    li {
        width: 15%;
      label {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: rgba($heading-color, 0.7);
      }

      .detail-list {
        span {
          font-weight: 500;
          font-size: 13px;
          line-height: 20px;
          position: relative;
          color: rgba($heading-color, 0.87);
          padding-right: 15px;
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          &::before {
            content: "\2022";
            font-weight: bold;
            font-size: 20px;
            display: inline-block;
            vertical-align: middle;
            position: absolute;
            top: 0px;
            left: -12px;
            opacity: 0.4;
          }

          &:first-child {
            &::before {
              display: none;
            }
          }
        }
      }

      &:last-child {
        width: 23%;
      }
    }
  }
}

.sales-mapped-user-outer {
  margin-top: 30px;
  display: flex;
  align-items: start;
  justify-content: space-between;

  h3 {
    font-weight: 600;
    font-size: 18px;
    line-height: 30px;
    color: $heading-color;
    padding-bottom: 10px;
  }

  .sales-users-left-panel {
    width: 49%;

    h3 {
      margin-left: 15px;
    }
  }

  .sales-mapped-bg {
    height: 530px;
    background: $white;
    border: 1px solid #E0E0E0;
    box-shadow: 0px 10px 35px rgba(0, 0, 0, 0.04);
    border-radius: 15px;
    padding: 30px;
  }

  .sales-user-list {
    ul {
      padding-top: 10px;
      height: 420px;
      overflow-y: auto;
      width: calc(100% + 60px);
      margin-left: -30px;
      margin-right: -30px;
      padding: 10px 30px 0;

      li {
        padding: 15px 30px;
        border-bottom: 1px solid $border-color;
        margin: 0px -30px;
        position: relative;

        label {
          width: 96%;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          color: $heading-color;
          display: flex;
          position: relative;

          &::before {
            content: "\2022";
            font-weight: bold;
            font-size: 20px;
            display: inline-block;
            vertical-align: middle;
            opacity: 0.4;
            margin-right: 14px;
          }
        }

        span {
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          color: #666666;
          padding-left: 22px;
        }

        .listclose-ic {
          // content: "\e925";
          // font-family: "dc" !important;
          position: absolute;
          right: 30px;
          background: #EFF2F9;
          width: 24px;
          text-align: center;
          border-radius: 24px;
          padding: 5px 0px;
          top: 15px;
          cursor: pointer;
        }

        &:last-child {
          border-bottom: 0px;
        }
      }
    }
  }
}

.mapped-users-right-panel {
  width: 49%;

  .sales-user-list {
    position: relative;

    ul {
      height: 410px;
      // overflow-y: hidden;
      padding-top: 0px;

    }

    &.no-data-txt {
      ul {
        display: flex;
        align-items: center;
        justify-content: center;

        li {
          &::after {
            display: none;
          }
        }
      }
    }

    .map-to-yoddha{
      margin-top: 55px;
    }
  }

  .btn-submit {
    border-top: 1px solid $border-color;
    padding-top: 20px;
    margin-left: -30px;
    padding-left: 30px;
    // position: absolute;
    bottom: 30px;
    width: calc(100% + 60px)
  }

  .tooltio-ic {
    padding-left: 0px !important;
  }
}

.tootip-outer {
  .react-tooltip-lite {
    background: $white;
    box-shadow: 0px 2px 14px rgba(31, 45, 78, 0.14);
    border-radius: 6px;
    width: 280px !important;
    padding: 20px !important;
    // margin-left: 100px;
  }
  .react-tooltip-lite-arrow {
    border-bottom: 10px solid $white !important;
    // margin-left: 130px;
  }
  .controlled-example_close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 18px;
  }

  .user-detail {
    li {

      font-weight: 400;
      font-size: 12px;
      line-height: 30px;
      color: $heading-color;
      &::before {
        content: "\2022";
        font-weight: bold;
        font-size: 20px;
        display: inline-block;
        vertical-align: middle;
        opacity: 0.4;
        margin-right: 2px;
        position: relative;
        top: 0px;
      }
    }
  }
}

.map-dealer-main {
  .dealer-user-mapping-tabs {
    padding-bottom: 20px !important;
    padding-top: 20px !important;
  }
}




.popup-content {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  width: 350px;
  text-align: center;
}

.popup-content h3 {
  margin-bottom: 20px;
}

.popup-content .custom-control {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.popup-content-label{
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-buttons {
  display: flex;
  justify-content: center;
}

.popup-buttons button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.popup-buttons .cancel-btn {
  margin-right: 30px;
  background-color: #f0f0f0;
}

.popup-buttons .submit-btn {
  background-color: #007bff;
  color: #ffffff;
}
.popup-content-data{
  margin-left: 10px;
}

.inactive-popup{
  width: 100%;
  display: flex;
  flex-direction: column;
 // gap: 30px;

  .headers{
    display: flex;
    justify-content: space-between;
    align-items: end;
    >div{

      display: flex;
      justify-content: space-between;
      text-align: end;
      align-items: end;
      gap: 100px;
     
    }
  }

  .search-bx{
    width: 50%;
  }
  
  .assigned-partner-list {
    ul {
      padding-top: 10px;
      height: 420px;
      overflow-y: auto;
      width: calc(100% + 60px);
      margin-left: -30px;
      margin-right: -30px;
      padding: 10px 30px 0;

      li {
        display: flex;
        justify-content: space-between;
        padding: 15px 30px;
        border-bottom: 1px solid $border-color;
        margin: 0px -30px;
        align-items: center;

        .assigned-partner-and-right-arrow{
          display: flex;
          width: 45%;
          padding-block: 12px;
        }

        label {
          width:85%;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          color: $heading-color;
          display: flex;
          position: relative;
          gap: 20px;

          &::before {
            content: "\2022";
            font-weight: bold;
            font-size: 20px;
            display: inline-block;
            vertical-align: middle;
            opacity: 0.4;
            margin-right: 14px;
          }
        }

        span {
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          color: #666666;
          padding-left: 22px;
        }

        .listclose-ic {
          // content: "\e925";
          // font-family: "dc" !important;
         
          background: #EFF2F9;
          width: 24px;
          height: 24px;
          text-align: center;
          border-radius: 24px;
          padding: 5px 0px;
         
          cursor: pointer;
        }

        .map-all-leads-checkbox input[type="checkbox"] {
          appearance: none; /* Remove default checkbox styling */
          width: 24px;
          height: 24px;
          border-radius: 50%; /* Make it circular */
          background-color: white; /* White background initially */
          display: inline-flex;
          justify-content: center;
          align-items: center;
          border: 1px solid #acacac; /* Add purple border for contrast */
          cursor: pointer;
          transition: background-color 0.3s, border-color 0.3s; /* Smooth transition */
        }
        
        .map-all-leads-checkbox input[type="checkbox"]::before {
          content: "✔";
          color: white;
          font-size: 16px;
          font-weight: bold;
          display: none; /* Initially hide the checkmark */
        }
        
        .map-all-leads-checkbox input[type="checkbox"]:checked {
          background-color: #6c4aff; /* Purple background when checked */
          border-color: #6c4aff; /* Change border color to match background */
        }
        
        .map-all-leads-checkbox input[type="checkbox"]:checked::before {
          display: block; /* Show the checkmark when checked */
        }
        
        &:last-child {
          border-bottom: 0px;
        }
       
      }
    }
  }

  .inactive-select-and-checkbox-container{
    display: flex;
    align-items: center;
    gap: 100px;
  }

  .inactive-popup-submit{
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    background-color: #6147ff;
    color: white;
    width: 200px;
    margin-left: 80%;
  }
}