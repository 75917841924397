.btn-agent-detail {
    .btn-primary {
        min-width: auto;
        padding: 0px 20px;
        font-size: 12px;
    }

    .btn-line {
        i {
            margin-right: 4px;
        }

        .ic-history {
            font-size: 14px !important;
        }
    }
}

.agent-detail-profile {
    width: 100%;
    border-radius: 8px;
    padding: 30px 40px;
    background: $white;
    display: flex;
    align-items: center;

    .role-detail-preview {
        ul {
            li {
                width: 130px;

            }

           
        }
    }
}

.profile-detail-role-detail {
    span {
        font-weight: 400;
        font-size: 11px;
        line-height: 16px;
        color: rgba($heading-color, 0.6);
        display: block;
    }

    ul {
        display: flex;
        align-items: center;

        li {
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;
            color: $heading-color;
            width: auto !important;
            display: flex;
            margin-right: 0px;
            white-space: nowrap;

            &::after {
                content: '>';
                display: inline-block;
                padding: 0px 5px;

            }
            &:last-child{
                &::after {
                    display: none;
                }
            }
        }
    }
}

.agent-detail-tads {
    // background: $tablebackground;
    // padding: 30px 40px;
    border-radius: 8px;
    margin-top: 20px;

    .preview-confirmation {
        .top-heading {
            background: $tableheader;
            padding: 10px 40px;
            // width: calc(100% + 80px);
            // margin-left: -40px;
            // margin-right: -40px;
            margin-top: 15px;
            margin-bottom: 30px;

            h2 {
                margin-bottom: 0px;
            }

            .edit-opt {
                padding-bottom: 0px;
            }
        }
    }

    .verify-txt {
        font-weight: 400;
        font-size: 11px;
        line-height: 16px;
        width: 50px;
        height: 22px;
        background: #E0E6F4;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 5px;
        cursor: pointer;
    }

    h3 {
        display: flex;
        align-items: center;
    }

    .role-detail-preview {
        ul {
            li {
                width: 220px;

                &.address-txt {
                    width: calc(100% - 250px);
                }
            }
        }
    }
}

.verify-doc-detail-popup{
    .modal-main{
        width: 985px;
        .modal-body{
            display: flex;
            height:470px;
        .slider-left-sec{
            width: 600px;
            margin-right: 20px;
            position: relative;
            padding-top: 4px;
            .slick-arrow.slick-prev{
                left: 0px;
                z-index: 10;
            }
            .slick-arrow.slick-next{
                right: 0px;
            }
            .image-opt{
                position: absolute;
                bottom: 20px;
            }
            .btn-re-upload{
                position: absolute;
                right: 0px;
                bottom: 14px;

            }
        }
    }
    .modal-header{
        margin-bottom: 30px;
    }
    h2{
        padding: 0px;
        margin: 0px;
    }
    }
    .slider-right-sec {
        border-left: 1px solid $border-color;
        padding-left: 40px;
        padding-top: 60px;
        
    }
    
}

.sticky-top {
    position: fixed;
    top: 0px;
    width: 100%;
    background: $white;
    // margin-left: -15px;
    padding: 0px 15px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    z-index: 1;
}

.mapped-regions-heading{
    display: flex;
    align-items: center;
    background: $tableheader;
    padding: 11px 40px;
    // width: calc(100% + 80px);
    // margin-left: -40px;
    // margin-right: -40px;
    margin-top: 20px;
    h2{
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        color: $heading-color;
    }
    span{
        font-weight: 400;
        font-size: 14px;
        line-height: 30px;
        color: $heading-color;
        margin-left: 5px;
    }
}
.mapped-regions-data{
   
        ul{
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            padding-top: 10px;
            li{
                height: 36px;
                font-weight: 400;
                font-size: 13px;
                line-height: 20px;
                color: rgba($heading-color, 0.8);
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0px 20px;
                margin: 10px 10px 10px 0;
                white-space: nowrap;
                border: 1px solid $border-color;
                border-radius: 100px;

            }
        }
    
}

.tab-bg{
    .tab-list{
        display: flex;
        align-items: center;
        .nav-item{
            margin-right: 10px;
        .nav-link {
            border: 1px solid $border-color;
            padding-bottom: 8px;
            border-radius: 100px;
            font-weight: 400;
            font-size: 12px;
            color: rgba($heading-color, 0.8);
            height: 36px;
            display: flex;
            align-items: center;
            justify-content: center;
            white-space: nowrap;
            padding: 0px 20px;
            cursor: pointer;
            &:hover{
              background: $accent-color;
              color: $white;
              border-color: $accent-color; 
            }

            &.active {
                background: $accent-color;
                color: $white;
                border-color: $accent-color;
            }
        }
    }
    }
}

.tab-bg-new{
  .tab-list{
      display: flex;
      align-items: center;
      .nav-item{
          margin-right: 10px;
      .nav-link {
          border-bottom: 2px solid $border-color;
          padding-bottom: 8px;
          // border-radius: 100px;
          font-weight: 400;
          font-size: 12px;
          color: rgba($heading-color, 0.8);
          height: 36px;
          display: flex;
          align-items: center;
          justify-content: center;
          white-space: nowrap;
          padding: 0px 20px;
          cursor: pointer;
          &:hover{
            // background: $accent-color;
            color: $accent-color;
            border-color: $accent-color; 
          }

          &.active {
              // background: $accent-color;
              color: $accent-color;
              border-color: $accent-color;
          }
      }
  }
  }
}
.reportess-tabs{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $white;
    padding: 15px 10px;
    margin-top: 20px;
    .search-bx{
        max-width: 300px;
    }
}

.reportees-data-table{
    // width: calc(100% + 80px);
    margin: 30px 0px;
    table{
        thead{
            th{
                 position: relative;
            }
        }
        th,td{
          width: 10%;
          &:nth-child(1){
             width: 18%;

          }
          &:nth-child(2){
            width: 8%;

         }
          &:nth-child(3){
            width: 18%;

         }
        }
    }
}

// .sorting_asc {
//     &:after {
//         content: "\e922";
//         font-family: dc !important;
//         /* display: inline-block; */
//         margin-left: -4px;
//         font-size: 29px;
//         position: absolute;
//         top: 15px;
//     }
//   }
  

  .image-uploader-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 0 0;
    .display-box {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: #eff2f9;
      min-height: 323px;
      width: 100%;
      padding-top: 30px;
      border-radius: 8px;
      &:hover,
      &.drag-over {
        .icon-text-box {
          // background-color: #96a599;
          cursor: pointer;
          // color: #fff;
          // text-shadow: 0px 1px 0 #999;
          .upload-icon {
            // transform: translateY(-20px);
            // animation: bounce 1s ease-in-out infinite;
          }
        }
      }
      .icon-text-box {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        text-shadow: 0px 0px 0 #999;
        transition: background-color 0.2s ease-in, color 0.2s ease-in, border-color 0.2s ease-in, text-shadow 0.2s ease-in;
        .upload-icon {
          width: 60px;
          height: 60px;
          margin: 0;
          box-sizing: border-box;
          transform: translateY(0px);
          transition: transform 0.2s ease-in-out;
          background: #fff;
          border-radius: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          i {
            font-size: 38px;
          }
        }
        .upload-button,
        .cancel-upload-button {
          margin: 0 10px;
          position: relative;
          z-index: 9999;
        }
  
        // .error-notification {
        //   animation: fadeInDown 0.5s;
        //   position: absolute;
        //   top: 20px;
        //   background-color: #dfb852;
        //   border-radius: 5px;
        //   color: #444;
        //   padding: 5px 10px;
        //   p {
        //     margin: 0;
        //     text-shadow: none;
        //   }
        // }
      }
      .upload-image-input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
      }
    }
  }
  .upload-txt {
    padding-top: 24px;
    h4 {
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: $heading-color;
      padding-bottom: 7px;
    }
    span {
      font-weight: normal;
      font-size: 12px;
      line-height: 22px;
      color: rgba($heading-color, 0.5);
      padding: 0px 50px;
      display: block;
      line-height: 18px;
    }
    .or-txt {
      text-transform: uppercase;
      padding-top: 10px;
      font-weight: normal;
      font-size: 12px;
      line-height: 22px;
      color: $heading-color;
    }
    .error-msg {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      text-align: center;
      color: #e02020;
      opacity: 1;
      display: block;
      padding: 5px 50px;
    }
    .file-btn {
      margin: 13px 0px 30px;
    }
  }
  
  .file-btn {
    min-width: 177px;
    box-shadow: none;
    margin: 23px 0px 30px;
  }
  .upload-again-btn {
    margin-top: 15px;
  }

  .bulk-upload-popup{
    .modal-main{
        width: 500px;
    }
 .download-txt {
    font-size: 12px;
    line-height: 22px;
    font-weight: 400;
    color: rgba(0,0,0,.6);
    margin-top: 12px;
    margin-bottom: 20px;
    a {
        text-decoration: underline;
        color: #000;
        margin-left: 5px;
    }
}
 
  }
  .upload-file-img {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 10px 0;
    span.suceess-icn {
      height: 18px;
      width: 18px;
      background: #44d7b6;
      border-radius: 100%;
      color: #fff;
      font-size: 13px;
      font-weight: bold;
      margin-right: 5px;
    }
  }
  
  .upload-file-name {
    h4,
    .upload-img-name {
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      color: $heading-color;
      padding: 20px 0;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 7px;
  
      i {
        background: #fff;
        height: 20px;
        width: 20px;
        border-radius: 100%;
        font-size: 12px;
        display: block;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 10px;
      }
    }
    .btn-primary {
      margin-bottom: 40px !important;
      background: #fff;
      color: #000;
      box-shadow: none;
      border: 1px solid #8c8c8c;
      min-width: 195px;
    }
    .upload-file-img {
      display: flex;
      align-items: flex-start;
      padding: 20px 20px;
      width: 100%;
      justify-content: center;
  
      span.suceess-icn {
        display: block;
        min-width: 18px;
        position: relative;
        top: 5px;
      }
      h4 {
        padding-top: 0px;
        white-space: normal;
        flex-wrap: wrap;
        word-break: break-all;
      }
      .ic-clearclose {
        background: #fff;
        height: 20px;
        width: 20px;
        min-width: 20px;
        border-radius: 100%;
        font-size: 12px;
        display: block;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 10px;
        position: relative;
        top: 5px;
      }
    }
    .file-btn {
      margin: 13px 0px 30px;
    }
    .or-txt {
      text-transform: uppercase;
      padding-top: 10px;
    }
  }
  
  .overflow-hidden {
    overflow: hidden;
  }
  
  .display-block {
    display: block;
  }
  
  .display-none {
    display: none;
  }
  
  .agent-detail-outer{
    background: $white;
    padding: 20px;
    border-radius: 8px;
  }

  .react-select--is-disabled{
    .react-select__control--is-disabled{
      background: $white !important;
      height: 42px;
    border-radius: 15px !important;
    outline: none !important;
    box-shadow: none;
    border-color: $input !important;
    }
  }

.text-right{
  text-align: right;
}

.role-history-popup{
  .modal-main{
    width: 1000px;
    background: lightcyan;
  }
  // .modal-body{
  //   margin-top: 20px;
  // }
}
.slider_image_text{
  max-width: 460px;
  overflow: hidden;
}

.mapping-history-popup{
  position: absolute;
  margin-left:79%;
  margin-top:-6%;
}