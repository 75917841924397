.user-management-fiter {
    fieldset {
        width: 12%;
        margin-right: 8px;

        &.rangepicker-calendra {
            width: 14%;
        }
    }

    .more-filter-option {
        fieldset {
            margin-bottom: 15px;

        }
    }
}

.top-heading {
    align-items: center;
    justify-content: space-between;

    &.d-flex {
        display: flex;
    }

    .right-btn {
        display: flex;
        align-items: center;
        
        .btn-line {
            display: flex;
            align-items: center;
            justify-content: center;

            i {
                font-size: 16px;
            }            
        }

    }
}
.btn_vendor{               
    @extend .button;
    border: 1px solid $promotional-color;
    color: $heading-color;                    
    background: #fff;                    
    min-width: auto;
    padding-right: 15px;
    padding-left: 15px; 
    cursor: default;              
    i{margin-right: 0px !important;cursor: pointer;}
}

.user-tabs-outer {
    margin-bottom: 0px;

    h2 {
        font-weight: 500;
        font-size: 18px;
        line-height: 30px;
    }

    .user-tabs {
        background: $btncolor;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
        border-radius: 8px;
        padding: 0px 20px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;

        ul {
            display: flex;
            align-items: center;
            // padding-top: 20px;

            li {
                text-align: center;
                border-bottom: 2px solid transparent;
                padding: 16px;
                display: flex;
                align-items: center;
                background: $btncolor;
                border-right: 1px solid $input;
                // box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.37);
                
                label {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 36px;
                    cursor: pointer;
                    color: $white;

                    a {
                        color: inherit;
                    }
                    &.all-user {
                        color: $white;
                    }

                    &.total-user {
                        color: #487CFD;
                    }

                    &.active-user {
                        color: #34C48B;
                        display: flex;
                        align-items: center;
                    }

                    &.inactive-user {
                        color: #F83939;
                    }

                    &.inprocess-user {
                        color: #FFA340;
                    }
                }

                span {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 16px;
                    display: block;
                    color: $white;
                }

                &.active {
                    border-bottom: 2px solid $accent-color;
                    background: $white;

                }
            }
        }
    }
}

.user-managemnt-table {
    table {
        tbody {
            tr {
                td {
                    a {
                        color: rgba($heading-color, 0.6);
                        text-decoration: underline;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

.user-managemnt-detail-table {
    background: $white;
    border-radius: 8px;

    &.data-table {
        table {
            thead {
                tr {
                    th {
                        line-height: 18px;
                    }
                }
            }

            tr {

                td,
                th {
                    &:first-child {
                        width: 6%;
                    }

                    &:nth-child(2) {
                        width: 10%;
                    }

                    &:nth-child(3) {
                        width: 6%;
                    }

                    &:nth-child(4) {
                        width: 13%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    &:nth-child(5) {
                        width: 7.5%;
                    }

                    &:nth-child(6) {
                        width: 8%;
                        white-space: normal;
                    }

                    &:nth-child(7) {
                        width: 7%;
                        white-space: normal;
                    }

                    &:nth-child(8) {
                        width: 8%;
                    }

                    &:nth-child(9) {
                        width: 8%;
                        white-space: normal;
                    }

                    &:nth-child(10) {
                        width: 6%;
                        white-space: normal;
                    }

                    &:last-child {
                        width: 14%;
                    }
                }

                .action-btn {
                    display: flex;
                    align-items: center;

                    li {
                        height: 32px;
                        width: 32px;
                        min-width: 32px;
                        border-radius: 4px;
                        border: 1px solid $border-color;
                        margin-right: 8px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 16px;
                        cursor: pointer;

                        a {
                            color: rgba($heading-color, 0.6);
                        }
                    }


                }
            }
        }
    }
}

.status-active {
    &::before {
        content: '';
        width: 8px;
        height: 8px;
        background: #44D7B6;
        position: relative;
        top: -2px;
        border-radius: 100%;

    }
}


.status-inprocess {
    &::before {
        content: '';
        width: 8px;
        height: 8px;
        background: #EE8F00;
        position: relative;
        top: -2px;
        border-radius: 100%;
        margin-right: 10px;
        display: inline-block;
    }
}


.user-action-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 0px 40px 0 0;

    .btn-line {
        margin-left: 10px;
        min-width: auto;
        padding: 0px 20px;
    }
}

.user-managemnt-detail-tabs {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 40px;
    background: $btncolor;
    //padding: 20px;

    .user-managemnt-new-tabs{
        background: $btncolor;
        padding: 20px;
    }
    .search-bx {
        margin-right: 0px;
        width: 250px;
        margin-bottom: 0px;
    }

    // .tab-line {
    //     .tab-list {
    //         .nav-item {
    //             margin-right: 30px;
    //         }
    //     }
    // }
}

.user-management-detail-tab-outer {
    background: $white;
    border-radius: 8px;
}

.anchor-txt {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: rgba($heading-color, 0.6);
    text-decoration: underline;

}


.allowance-card-table {
    margin-bottom: 40px;

    table {
        width: 70%;
    }
}

.Configuration-filter {
    border-bottom: 1px solid $border-color;
    padding-bottom: 40px;
    margin-bottom: 40px;

    .heading-txt {
        font-weight: 500;
        font-size: 16px;
        line-height: 30px;
        color: $heading-color;
        padding-bottom: 20px;
        display: block;
    }

    .configuration-filed-outer {
        fieldset {
            margin-right: 15px;
        }

        table {
            th {
                text-align: left;
                padding-bottom: 8px;
                font-weight: 500;
                font-size: 16px;
                line-height: 30px;
                color: $heading-color;
            }

            td {
                padding-bottom: 20px;
            }

            th,
            td {
                &:nth-child(1) {
                    width: 40%;
                }

                &:nth-child(2) {
                    width: 20%;
                    margin-left: 10px;
                }

                &:nth-child(3) {
                    width: 20%;
                    margin-left: 10px;
                }

                &:nth-child(4) {
                    width: 40%;
                    margin-left: 10px;
                }
            }
        }

        .chip-set {
            margin-top: 20px;
            .chips {
                display: inline-flex;
                // position: relative;
                background-color: rgba(0,0,0,.12);
                // color: rgba(0,0,0,.87);
                height: 32px;
                padding: 5px 10px;
                // border-width: 0;
                // outline: none;
                cursor: pointer;
                border-radius: 16px;
                margin-right: 5px;
            }
        }
    }

    .bm-configuration-filed-outer {
        fieldset {
            margin-right: 15px;
        } 
        table {
            width: 60%;
            th {
                text-align: left;
                padding-bottom: 8px;
                font-weight: 500;
                font-size: 16px;
                line-height: 30px;
                color: $heading-color;
            } 
            td {
                padding-bottom: 20px;
            } 
            th,
            td {
                &:nth-child(1) {
                    width: 60%;
                }

                &:nth-child(2) {
                    width: 40%;
                    margin-left: 10px;
                } 
            }
        } 
        .chip-set {
            margin-top: 20px;
            .chips {
                display: inline-flex; 
                background-color: rgba(0,0,0,.12); 
                height: 32px;
                padding: 5px 10px; 
                cursor: pointer;
                border-radius: 16px;
                margin-right: 5px;
            }
        }
    }
    .btn-line {        
        min-width: auto;
        padding: 0px 20px;
    }    
}

.user-incentive-type2 {
    table {
        width: 70%;

        th,
        td {
            width: 200px;

            &:nth-child(3) {
                text-align: center;
            }
        }
    }
}

.add-incentive-accordion {
    margin-top: 20px;
}

.redeemable-select-finacer-list {
    margin-bottom: 40px;
    .sub-heading {
        font-weight: 500;
        font-size: 16px;
        line-height: 30px;
        color: $heading-color;
        padding-bottom: 20px;
        display: block;
    }
    table{
     width: 40%;   
    }
}

.booster-txt-filed{
    margin-bottom: 40px;
    span{
        font-weight: 500;
        font-size: 16px;
        line-height: 30px;
        color: $heading-color;
    }
    .material{
         .form-input{
            width: 235px;

         }
        }
}

.extra-bonus-bro-filter{
    width: 100%;
    .basic-detail-filter{
        fieldset{
            width: 15%;
        }
        .rangepicker-calendra {
            width: 18%;
        }
    }    
}

.w-100{
    width: 100%;
}

.special-sheme-incentive-amt{
    margin-bottom: 40px;
    span{
        font-weight: 500;
        font-size: 16px;
        line-height: 30px;
        color: $heading-color;
    }
    .material{
         .form-input{
            width: 370px;

         }
        }
}
.special-sheme-filed{
    table{
        width: 60%;
    }
}


.add-incentive-popup{
    .add-incentive-data{
        ul{
            li{
                font-weight: 400;
                font-size: 14px;
                line-height: 45px;
                border-bottom: 1px solid $border-color;
                position: relative;
                &::after{
                    content: "\e919";
                    font-family: "dc" !important;
                    position: absolute;
                    right: 0px;
                    font-size: 24px;
                    color: rgba($heading-color, 0.5);
                }
                &:last-child{
                    border-bottom: 0px;
                }
            }
        }
    }
}

.config-heading{
    font-weight: bold;
    color: black;
    font-size: 18px;
    margin-bottom: 20px;
    display: block;
}

.disabled {
    background-color: rgba(239, 239, 239, 0.3);
    border-color: rgba(118, 118, 118, 0.3);
  }
  
  button:disabled,
  button[disabled] {
    border: rgba(187, 187, 187, 0.7);
    background-color: rgba(187, 187, 187, 0.7);
    color: #666666 !important;
    box-shadow: none;
    cursor: not-allowed;
  }

  .tootip-outer-refinance {
    .react-tooltip-lite {
        background: $white;
        box-shadow: 0px 2px 14px rgba(31, 45, 78, 0.14);
        border-radius: 6px;
        width: 350px !important;
        padding: 20px !important;
        // margin-left: 100px;
    }
    
    .react-tooltip-lite-arrow {
        border-bottom: 10px solid $white !important;
        // margin-left: 130px;
    }
    
    .controlled-example_close-button {
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 18px;
    }
    
    
        span {
            cursor: pointer;
            font-size: 12px;
            line-height: 18px;
            width: 190px;
            padding-right: 10px;
            color: rgba($heading-color, 0.7);
        }
    
        label {
            font-size: 13px;
            width: calc(100% - 100px);
            font-size: 13px;
            color: $heading-color;
        }
  }